import { AxiosInstance } from 'axios'
import { convertWebNode } from '~/lib/api/deserializers/webNode'
import { WebNode } from '@/types/webNode'

export default function (instance: AxiosInstance) {
  const base = 'api/aspos/webnodes'

  return {
    async getByUrl(url: string): Promise<WebNode | null> {
      const { data } = await instance.get(`/${base}/url/${url}`)
      if (!data.Success) return null

      return convertWebNode(data.Data)
    },

    async getRoot(descendantLevel?: number): Promise<WebNode | null> {
      const { data } = await instance.get(`/${base}/main/root`, {
        params: { descendantLevel },
      })
      if (!data.Success) return null

      return convertWebNode(data.Data)
    },

    async getMainById(
      id: number,
      descendantLevel?: number
    ): Promise<WebNode | null> {
      const { data } = await instance.get(`/${base}/main/${id}`, {
        params: { descendantLevel },
      })
      if (!data.Success) return null

      return convertWebNode(data.Data)
    },
  }
}
