import { AxiosInstance } from 'axios'
import {
  TranssmartCheckoutRequest,
  TranssmartCheckoutResponse,
  TranssmartConfig,
  TranssmartDeliveryMapCountry
} from '~/types/transsmart'

export default function (instance: AxiosInstance) {
  const base = 'api/transsmart'

  const mapCountry = (dataCountry: any): TranssmartDeliveryMapCountry => ({
    countryCodes: dataCountry.CountryCodes,
    options: dataCountry.Options.map((dataOption: any) => ({
      optionId: dataOption.OptionId,
      deliveryType: dataOption.DeliveryType
    }))
  })

  return {
    async checkout(request: TranssmartCheckoutRequest): Promise<TranssmartCheckoutResponse | null> {
      const response = await instance.get(`/${base}/delivery-checkouts`, {
        params: {
          toZipcode: request.postalCode ?? '',
          toCountry: request.countryCode ?? '',
          city: request.city
        }
      })
      if (!response.data.Success) return null

      const dataConfig = response.data.Config
      const mappedConfig: TranssmartConfig = {
        deliveryMap: {
          countries: dataConfig.DeliveryMaps
            .filter((dataCountry: any) => !dataCountry.CountryCodes.includes('Default'))
            .map(mapCountry),
          default: mapCountry(dataConfig.DeliveryMaps.find(
            (dataCountry: any) => dataCountry.CountryCodes.includes('Default')
          ))
        }
      }

      return {
        data: response.data.Data,
        locations: response.data.Locations,
        config: mappedConfig
      }
    }
  }
}
